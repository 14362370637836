import styled from 'styled-components';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

export const ExtraPadding = styled.div`
  padding-left: 0.625rem;
  padding-right: 0.625rem;

  @media only screen and (min-width: 769px) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  max-width: 1440px;
  margin: 0 auto;

  @media only screen and (min-width: 769px) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
`;

export const Row10Col = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
`;

export const Row8Col = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
`;

export const RowCenter = styled(Row)`
  justify-content: center;
`;

export const RowMobileWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  max-width: 1440px;
  margin: 0 auto;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
`;

export const Button = styled(Link)`
  text-transform: uppercase;
  color: #fff;
  padding: 0.5rem 1.5rem;
  display: block;
  border: 1px solid #fff;
  border-radius: 3px;
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 700;
  display: inline-block;

  -webkit-transition: background-color 0.2s linear, border-color 0.2s linear,
    color 0.2s linear;
  transition: background-color 0.2s linear, border-color 0.2s linear,
    color 0.2s linear;

  &:hover {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
    background-color: #7b7b7b82;
  }

  &:active {
    background-color: #7b7b7b82;
    color: #cdcdcd;
    border-color: #cdcdcd;
  }

  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;
    outline: 2px dotted transparent;
    outline-offset: 2px;
  }
`;

export const ButtonPrimary = Button;

export const ButtonSecondary = styled(Button)`
  border-color: #bd8b13;
  background-color: #bd8b13;

  &:hover {
    border-color: #a67b10;
    background-color: #a67b10;
    color: #fff;
  }

  &:active {
    background-color: #d9ae43;
  }
`;

export const BackpageBannerWrapper = styled.div`
  position: relative;

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(0, 0, 0, 0.25);
  }
`;

export const BackpageBanner = styled(BackgroundImage)`
  height: 150px;

  @media only screen and (min-width: 480px) {
    height: 200px;
  }

  @media only screen and (min-width: 769px) {
    height: 300px;
  }
`;

export const PageTitle = styled.h1`
  text-transform: uppercase;
  text-align: center;
  color: #181816;
  margin-bottom: 1rem;
`;

export const MainPageContent = styled.main`
  padding-top: 1.5rem;
  background-color: white;
  position: relative;
  top: -30px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;

  .inner-content {
    *:last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 769px) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    top: -90px;
  }
`;
