import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Row, RowMobileWrap } from './/CustomComponents';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share';

import facebook from '../img/social/facebook.svg';
import pinterest from '../img/social/pinterest.svg';
import twitter from '../img/social/twitter.svg';

const FooterMainContent = styled.section`
  padding: 1.5rem 0;
  background-color: #222222;

  img {
    margin-bottom: 0;
  }

  a {
    border: none;
    color: white;
    background: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: #999999;
    }
  }
`;

const FooterLeftContent = styled.div`
  display: flex;

  .logo-wrapper {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    .logo-wrapper {
      margin-bottom: 1rem;
    }
  }
`;

const ContactBlock = styled.div`
  margin-bottom: 1rem;

  @media only screen and (min-width: 769px) {
    margin-left: 7rem;
    margin-bottom: 0;
  }

  * {
    margin-bottom: 0;
  }
`;

const FooterChin = styled.section`
  background-color: #000000;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  .share-button {
    margin-right: 1rem;

    @media only screen and (min-width: 769px) {
      margin-left: 1rem;
      margin-right: 0;
    }

    position: relative;

    &:after {
      content: '';
      position: absolute;
      border-bottom: 2px solid transparent;
      bottom: 0;
      width: 1.25em;
      display: inline-block;
      left: 1px;
      -webkit-transition: border-color 0.2s linear;
      transition: border-color 0.2s linear;
    }

    &:hover {
      &:after {
        border-color: #999999;
      }
    }
  }
`;

const FOOTER_LOGO_QUERY = graphql`
  query LogoWhite {
    file(relativePath: { eq: "logo-white.png" }) {
      childImageSharp {
        fixed(width: 191) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

export default function Footer() {
  const { file } = useStaticQuery(FOOTER_LOGO_QUERY);
  return (
    <div>
      <footer>
        <FooterMainContent>
          <RowMobileWrap>
            <FooterLeftContent>
              <Link to="/" className="logo-wrapper">
                <Img
                  fixed={file.childImageSharp.fixed}
                  alt={'Bella Montaña Homes Logo'}
                />
              </Link>
              <ContactBlock>
                <h4
                  style={{
                    color: '#999999',
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontWeight: 700,
                    marginTop: 0,
                    fontSize: '1rem',
                    textTransform: 'uppercase',
                    lineHeight: 1.7,
                    letterSpacing: '0.5px',
                    paddingLeft: '2px',
                  }}
                >
                  Contact
                </h4>
                <p>
                  <a href="tel:8057561131">(805) 756-1131</a>
                </p>
                <p>
                  <a href="mailto:realestate@calpoly.edu">
                    realestate@calpoly.edu
                  </a>
                </p>
              </ContactBlock>
            </FooterLeftContent>
            <LogoWrapper>
              <FacebookShareButton
                url={`https://bellamontanahomes.com`}
                className="share-button"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  style={{ width: '1.25em', height: '1.25em' }}
                />
              </FacebookShareButton>
              <TwitterShareButton
                url={`https://bellamontanahomes.com`}
                className="share-button"
              >
                <img
                  src={twitter}
                  alt="Twitter"
                  style={{ width: '1.25em', height: '1.25em' }}
                />
              </TwitterShareButton>
              <PinterestShareButton
                url={`https://bellamontanahomes.com`}
                media={`https://bellamontanahomes.com/img/og-image.jpg`}
                className="share-button"
              >
                <img
                  src={pinterest}
                  alt="Instagram"
                  style={{ width: '1.25em', height: '1.25em' }}
                />
              </PinterestShareButton>
            </LogoWrapper>
          </RowMobileWrap>
        </FooterMainContent>
        <FooterChin>
          <Row>
            <p
              style={{
                padding: '0.5rem 0',
                margin: '0',
                color: '#999999',
                fontSize: '.9rem',
              }}
            >
              &copy; {new Date().getFullYear()} || Bella Monta&ntilde;a || Cal
              Poly Partners
            </p>
          </Row>
        </FooterChin>
      </footer>
    </div>
  );
}
