import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import useSiteMetadata from './SiteMetadata';
import { withPrefix } from 'gatsby';

import '../styles/Reset.scss';
import '../styles/Typography.scss';
import '../styles/InlineButtons.scss';

import MobileNav from './MobileNav';

const TemplateWrapper = ({
  children,
  location,
  pageMetaTitle,
  pageMetaDescription,
}) => {
  const { siteMetaTitle, siteMetaDescription } = useSiteMetadata();

  const pageLocation = location.pathname === '/' ? 'home' : 'backpage';

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{`${pageMetaTitle} | ${siteMetaTitle}`}</title>
        <meta
          name="description"
          content={`${pageMetaDescription || siteMetaDescription}`}
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#154734" />

        <meta property="og:type" content="business.business" />
        <meta
          property="og:title"
          content={`${pageMetaTitle} | ${siteMetaTitle}`}
        />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar location={location} />
      <MobileNav />
      <div>
        <div
          className={pageLocation}
          style={{
            paddingTop: pageLocation === 'home' ? 0 : '95px',
          }}
        >
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

TemplateWrapper.defaultProps = {
  location: {},
};

export default TemplateWrapper;
