import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Link as SmoothLink } from 'react-scroll';

import { Row } from './CustomComponents.js';

const NavigationWrapper = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  min-height: 100px;
  z-index: 999;

  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);

  &.home {
    background: transparent;

    .nav-logo {
      opacity: 1;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    &:not(.minified) {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      a {
        color: white;
        &:hover {
          border-color: #154734;
        }
      }

      .nav-logo {
        opacity: 0;
      }
    }
  }

  &.minified {
    background: #ffffff;
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    min-height: 75px;

    .mobile-nav-wrapper {
      margin-top: -0.3rem;
    }
  }

  .mobile-nav-wrapper {
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin-top: -0.5rem;
  }

  img {
    margin-bottom: 0;
  }

  a {
    border-color: transparent;
    font-size: 0.9rem;
    display: inline-block;
    margin: 0 0.35rem;
    background-color: transparent;
    -webkit-transition: border-color 0.2s linear, color 0.2s linear;
    transition: border-color 0.2s linear, color 0.2s linear;

    &:hover,
    &:active {
      border-color: #428269;
    }

    @media only screen and (min-width: 965px) {
      margin: 0 1rem;
    }
  }

  a.logo {
    background: none;
    border: none;
    line-height: 0;
    display: flex;
    align-items: center;
    margin: 0;
  }

  @media only screen and (min-width: 480px) {
    a.logo-mobile {
      display: none;
    }
  }

  @media only screen and (max-width: 479px) {
    a.logo-desktop {
      display: none;
    }
  }

  .navbar {
    a:last-child {
      margin-right: 0;
    }
  }
`;

const DesktopNavigation = styled.nav`
  .navigation-menus {
    .primary-nav {
      a {
        text-transform: uppercase;
        border-bottom-width: 3px;

        @media only screen and (min-width: 965px) {
          letter-spacing: 0.5px;
        }
      }
    }

    .secondary-nav {
      margin-bottom: 10px;
      a {
        color: #154734;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileNavigationWrapper = styled.nav`
  @media only screen and (min-width: 769px) {
    display: none;
  }

  a {
    color: #154734;
  }
`;

const NavMenu = styled.div`
  display: flex;
  justify-content: flex-end;

  .active-page {
    border-color: #428269;
  }
`;

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  handleScroll = event => {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      document.getElementById('navigation-wrapper').classList.add('minified');
      document.querySelector('.bm-burger-button').classList.add('minified');
    } else {
      document
        .getElementById('navigation-wrapper')
        .classList.remove('minified');
      document.querySelector('.bm-burger-button').classList.remove('minified');
    }
  };

  componentDidMount() {
    const pagePath = this.props.location.pathname;
    const isHomeSingle = pagePath.includes('/available-homes/');

    this.setState(
      {
        pagePath,
        isHomeSingle,
      },
      () => {
        window.addEventListener('scroll', this.handleScroll);
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query Logos {
            LogoColor: file(relativePath: { eq: "logo-color.png" }) {
              childImageSharp {
                fixed(width: 191) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            MobileLogoColor: file(
              relativePath: { eq: "mobile-logo-color.png" }
            ) {
              childImageSharp {
                fixed(width: 110) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <NavigationWrapper
              className={this.state.pagePath === '/' ? 'home' : 'backpage'}
              role="navigation"
              aria-label="main-navigation"
              id="navigation-wrapper"
            >
              <Row className={'right-on-small'}>
                <Link to="/" title="Logo" className="logo logo-mobile">
                  <Img
                    fixed={data.MobileLogoColor.childImageSharp.fixed}
                    alt="Bella Montaña Logo Abbreviated"
                    style={{
                      marginBottom: 0,
                    }}
                    className={'nav-logo'}
                  />
                </Link>
                <Link to="/" title="Logo" className="logo logo-desktop">
                  <Img
                    fixed={data.LogoColor.childImageSharp.fixed}
                    alt="Bella Montaña Logo"
                    style={{
                      marginBottom: 0,
                    }}
                    className={`nav-logo`}
                  />
                </Link>
                <DesktopNavigation>
                  <div className="navigation-menus">
                    <NavMenu className="navbar secondary-nav">
                      {this.state.pagePath === '/available-homes' ||
                      this.state.isHomeSingle ? (
                        <SmoothLink
                          activeClass="active"
                          to="interested-buyers"
                          spy={true}
                          smooth={true}
                          offset={-185}
                          duration={500}
                        >
                          Contact Us
                        </SmoothLink>
                      ) : (
                        <Link to="/available-homes#interested-buyers">
                          Contact Us
                        </Link>
                      )}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://calpolypartners.org/"
                      >
                        Back to Cal Poly Partners
                      </a>
                    </NavMenu>
                    <NavMenu
                      className={`primary-nav navbar ${this.state.navBarActiveClass}`}
                    >
                      <Link
                        to="/about"
                        className={
                          this.state.pagePath === '/about' ? 'active-page' : ''
                        }
                      >
                        About
                      </Link>
                      <Link
                        to="/home-designs"
                        className={
                          this.state.pagePath === '/home-designs'
                            ? 'active-page'
                            : ''
                        }
                      >
                        Home Designs
                      </Link>
                      <Link
                        to="/available-homes"
                        className={
                          this.state.pagePath === '/available-homes' ||
                          this.state.isHomeSingle
                            ? 'active-page'
                            : ''
                        }
                      >
                        Available Homes
                      </Link>
                      <Link
                        to="/faq"
                        className={
                          this.state.pagePath === '/faq' ? 'active-page' : ''
                        }
                      >
                        FAQ
                      </Link>
                      <Link
                        to="/resources"
                        className={
                          this.state.pagePath === '/resources'
                            ? 'active-page'
                            : ''
                        }
                      >
                        Resources
                      </Link>
                      {/* <Link
                        to="/faq"
                        className={
                          this.state.pagePath === '/faq' ? 'active-page' : ''
                        }
                      >
                        FAQ
                      </Link> */}
                      <Link
                        to="/community"
                        className={
                          this.state.pagePath === '/community'
                            ? 'active-page'
                            : ''
                        }
                      >
                        Community
                      </Link>
                    </NavMenu>
                  </div>
                </DesktopNavigation>
                <MobileNavigationWrapper className="mobile-nav-wrapper navbar secondary-nav">
                  <a
                    id=""
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://calpolypartners.org/"
                  >
                    Back to Cal Poly Partners
                  </a>
                </MobileNavigationWrapper>
              </Row>
            </NavigationWrapper>
          </div>
        )}
      />
    );
  }
};

export default Navbar;
