import Menu from 'react-burger-menu/lib/menus/slide';
import React from 'react';
import { Link } from 'gatsby';

import './MobileNav.scss';

export default class MobileNav extends React.Component {
  render() {
    return (
      <Menu right disableAutoFocus>
        <Link id="home" className="menu-item" to="/">
          Home
        </Link>
        <Link id="about" className="menu-item" to="/about">
          About
        </Link>
        <Link id="home-designs" className="menu-item" to="/home-designs">
          Home Designs
        </Link>
        <Link id="available-homes" className="menu-item" to="/available-homes">
          Available Homes
        </Link>
        <Link id="faq" className="menu-item" to="/faq">
          FAQ
        </Link>
        <Link id="resources" className="menu-item" to="/resources">
          Resources
        </Link>
        {/* <Link id="faq" className="menu-item" to="/faq">
          FAQ
        </Link> */}
        <Link id="community" className="menu-item" to="/community">
          Community
        </Link>
        <Link
          id="contact"
          className="menu-item"
          to="/available-homes#interested-buyers"
        >
          Contact Us
        </Link>
        <a
          id="back-to-cpc"
          className="menu-item"
          target="_blank"
          rel="noopener noreferrer"
          href="https://calpolypartners.org/"
        >
          Back to Cal Poly Partners
        </a>
      </Menu>
    );
  }
}
